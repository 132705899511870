.banner{
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    padding: 5% 0;
    padding-left: 3%;
    height: 30rem;
    /*border-bottom: 3px solid white;*/
    color: white;
    /* font-family: Asap; */
    padding-right: 7%;
}
#downCV{
    background: rebeccapurple;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin: 4em
}

#text-home{
    width: 70%;
    padding: 4% 10.5%;
    padding-right: 0;
}

#text-home h1{
    font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

#text-home p{
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
    margin-bottom: 7%;
}

#text-home a{
    border: 1px solid white;
    padding: 1.3% 2%;
    margin: 10px 2px;
    border-radius: 19px;
    color: white;
    text-decoration: none
}

#text-home a:hover {
    background-color: #123;
}


#image-banner{
    /* padding: 4% 10%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* width: 30%; */
    max-height: 70%;
    max-height: 70%;
    padding-top: 5%;
}


@media (max-width: 450px) {
    .banner{
        flex-wrap: wrap;
        margin-top: 8rem;
        padding-left: 4%;
        height: 42rem;
    }
    #image-banner{
        display: none;
        /* max-width: 80%;
        padding: 2%; */
        /* display: flex;
        justify-content: center;
        align-items: center;
        align-content: center; */
    }
}

@media (max-width: 345px){
#text-home{
    width: 85%;
}
}

@media (max-width: 635px){
    #image-banner{
        display: none;
    }
}

@media(min-width: 635px) and (max-width: 782px){
    #image-banner{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30%;
    }
    #image-banner img{
        width: 20em;
    height: 20em;
    }
}

@media (max-width: 1000px){
    .banner{
        padding-right: 0;
    }
    #text-home{
        padding-left: 0;
    }
} 