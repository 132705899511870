#skills{
	/*height: 30rem;
	/*padding: 0 6%;*/
	/*display: flex;*/
	/*justify-content: center;*/
	padding: 0 15%;
 	position: relative;
 	z-index: 10;
 	margin-bottom: 5rem;
 	margin-top: 6rem
}

#box-skills{
	background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
/*  margin-top: -60px;
*/
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  z-index: 2;
  position: relative;
}

#box-skills h2{
	/*font-size: 45px;*/
	font-weight: 700;
}

#box-skills p{
	color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}

#box-skills div{
	margin-bottom: 30px;
}

.box-glass{
	/*border: 1px solid white;*/
	z-index: 1;
	position: absolute;	
}

#box1{
	height: 50px;
	width: 50px;
	transform: translate(-20px, -50px);
	background: rgba( 255, 255, 255, 0.15 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 11px );
	-webkit-backdrop-filter: blur( 11px );
	border-radius: 10px;
}

#box2{
	height: 50px;
	width: 50px;	
	border-radius: 50%;
	transform: translate(50rem, -20px);
	background: rgba( 255, 255, 255, 0.15 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 9px );
	-webkit-backdrop-filter: blur( 9px );
	z-index: 10
}

#box-skills div ul li{
	width: 100px
}

.icon-carousel{
	height: 130px;
}

.icon-carousel img{
	height: 100%;
	min-width: 100px;
	max-width: 200px
}

@media (max-width: 1100px){
		#skills{
			padding: 0 3%;
			margin-top: 14rem;
		}
		#box-skills div{
			margin-bottom: 0;
		}


}

@media (max-width: 882px){
	#box2{
		display: none;
	}
	#skills{
			padding: 0 3%;
			margin-top: 13rem;
		}
}
@media (max-width: 500px){
	#box2{
		display: none;
	}
	#skills{
			padding: 0 3%;
			margin-top: 12rem;
		}
}