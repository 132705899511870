#contact{
    display: flex;
    align-items: center;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 0 3%;
}

#image-contact img{
    max-width: 100%;
}
.contact-box{
    width: 50%;
}

#form-contact{
    padding: 3%;
    color: white;
    margin-bottom: 5rem;
}

#form-contact div input, #form-contact div textarea{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: white;
}

.form-c{
    padding: 1em;
}

.g-2{
    margin: 0.3em 0;
}


#message-input textarea{
    height: 8em;
    color: white;
}

#btn-submit{
    border: 1px solid white;
    background: none;
    color: white;
    margin: 12px 0.3em;
    width: 7rem;
    height: 2.4rem;
    border-radius: 8px
}

#btn-submit:hover{
    background-color: white;
    color:#4A2FBD
}


@media (max-width: 1100px){

}

@media (max-width: 450px){
    #image-contact{
        display: none;
    }
    .contact-box{
        width: 100%
    }
    .form-c{
        align-items: center;
        text-align: center;
    }
    #form-contact{
        margin-bottom: 0;
    }
}
