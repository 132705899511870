.content{
    color: black;
}
.site-footer{
    background-color: #151515;
    padding: 3%;
    text-align: center;
}

.site-footer p{
    margin-top: 4em;
}