h1 h2 h3 h4 h5 h6{
    margin: 0;
    padding: 0;
    line-height: normal;
}

h1, h2{
    font-size: 1.6rem;
    font-weight: 700;
}

a{
    text-decoration: none;
}