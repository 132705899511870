/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

body {
  margin: 0;
  /* font-weight: 400; */
  /* letter-spacing: 0.04cm; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0;
    /* padding: 260px 0 100px 0; */
    background-color: #121212;
    font-family: 'Centra', sans-serif ;
    background-position: top center;
    background-size:cover;
    background-repeat: no-repeat;
    background-image: url('https://fondosmil.com/fondo/22298.jpg');
    /* background-image: url('https://w0.peakpx.com/wallpaper/966/473/HD-wallpaper-purple-lines-abstract-color-dark-shining.jpg'); */
    /*background-image: url('https://i.pinimg.com/originals/63/57/83/635783855829c3fab516dca2d10bc5c8.jpg');*/
    /* background: #1E1842 center top/cover no-repeat;   */
    /* background-image: url('./assets/prueba.gif'); */
}
    
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: white;
}