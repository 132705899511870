#about{
	/*height: 30rem;
	/*padding: 0 6%;*/
	display: flex;/*
	justify-content: center;*/
	text-align: center;
	padding: 4% 8%;
 	position: relative;
	background: #151515;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	/*filter: saturate(200%);*/
	padding-top: 4%;
}

#about h2{
	font-weight: 700
}
#box-about{
  	text-align: center;
/*  padding: 60px 50px;
*/  display: flex;
  	width: 100%;
  	padding-top: 1%;
}

#box-about div img{
	/*min-width: 95%;*/
}

#img-detail img{
	min-width: 95%;
}
.container-box{
	display: grid;
	width: 50%
}

#img-detail{
	/*width: 60%*/
	display: flex;
}

#cnt-2{
	/*width: 40%;*/
/*	margin-top: 5%
*/}

#title-about{
	max-width: 80%;
}

#details-about{
	display: grid;
	grid-template-columns: 47% 47%;
  	grid-template-rows: 47% 47%;
  	column-gap: 6%;
	row-gap: 6%;
	/*min-width: 100%;*/
	/*justify-content: space-evenly;*/
}


.box-detail-about{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#box-one{
	background-color: #28B6DC;
}

#box-two{
	background-color: #8A72EE;
}
#box-three{
	background-color: #5C3AE6;
}
#box-four{
	background-color: #BC72EF;
}




#box-about h2{
	font-size: 45px;
	font-weight: 700;
}

#box-about p{
	color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}



@media (max-width: 1100px){
		#about{
			padding: 3% 3%;
			padding-bottom: 6%;
			filter: opacity(0.8);
		}
		.container-box {
			width: 50%;
			display: grid;
		}
		#details-about {
		    display: grid;
		    grid-template-columns: 48% 48%;
		    grid-template-rows: 48% 48%;
		    column-gap: 4%;
		    row-gap: 4%;
		    /* min-width: 100%; */
		    /* justify-content: space-evenly; */
		}
}

@media (max-width: 450px){
		#img-detail{
			display: none;
		}

		#box-about{
			padding-bottom: 5%;
		}
		#cnt-2{
			display: flex;
			width: 100%;
			height: 70rem;
			padding: 0 15%;
		}
		#box-about div{
			width: 100%;
		}
		#details-about{
			display: flex;
			flex-wrap: wrap;
		}

}