nav{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 35px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
    color: white;
}

nav h2{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrolled{
    padding: 1% 0;
    background-color: #121212;
    margin-bottom: 0;
}

nav ul{
    /*width: 50%;*/
    /*padding: 1% 0;*/
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

nav ul li{
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.3px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
}

nav ul li:hover{
    opacity: 1;
}

#btn-social{
    display: flex;
    margin: 0 12px;
    width: 200px;
    margin-left: 0;
}

#btn-social a{
    border: 1px solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    background-color:  rgba(217, 217, 217, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    line-height: 1;
}

#btn-social a::before{
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}

.social-media a img:hover {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
/*.social-media a img{
    fill: white;
}*/
nav ul .button{
    border: 1px solid white;
    height: 103%;
    width: 22%;
    background-color: transparent;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5% 0.5%;
    text-align: center;
    box-sizing: content-box;
}
nav ul .button:hover{
    background-color: white;
}

nav ul .button:hover{
    color:#1E1842;

}
nav ul a {
    text-decoration: none;
    list-style: none;
    color: white;
}

@media (max-width: 458px) {
    nav{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    .scrolled{
        padding: 5% 0;
    }
    nav ul{
        width: 100%;
        padding: 2px
    }
    #btn-social{
        display: none;
    }
    nav ul li{
        padding: 0;
    }
}
@media (max-width: 640px){
    #btn-social{
        display: none;
    }
}

@media (max-width: 512px) and (min-width: 459px){
   nav ul li{
    padding-left: 10px;
    padding-right: 10px;
    }
}

@media (min-width: 460px) and  (max-width: 778px){

nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 460px) and  (max-width: 1000px){
    .scrolled{
        padding: 2% 0
    }

    
    nav ul{
        width: 75%
    }   
}




